
    import Vue from "vue";
    import Component from "vue-class-component";
    import FileUpload from "@/components/FileUpload.vue";
    import MultiDocument from "@/components/MultiDocument.vue";
    import { StateChanger } from "vue-infinite-loading";
    import ApiButton from "@/components/ApiButton.vue";
    import DualListBox from "@/components/DualListBox.vue";
    import TickOrCross from "@/components/TickOrCross.vue";
    import { required } from "vuelidate/lib/validators";
    import { Validations } from "vuelidate-property-decorators";
    import { Watch } from "vue-property-decorator";
    import * as toastr from "toastr";
    import apiClient from "@/stuff/ApiClient";
    import utils from "@/stuff/Utils";
    import fileDownload from "@/stuff/FileDownload";
    import { ModuleSearchParameters } from "@/model/ModuleSearchParameters";
    import { LookupItem, ILookupItem } from "@/model/LookupItem";
    import { Module, IModule } from "@/model/Module";
    import { Document as ModelDocument } from "@/model/Document";
    import { TriState, LookupGroup } from "@/model/Enums";

    @Component({
        components: { ApiButton, TickOrCross, DualListBox, MultiDocument, FileUpload }
    })
    export default class Modules extends Vue {

        async mounted() {
            await Promise.all([
                this.loadCdmCategories(),
                this.loadScopes(),
                this.loadTrades()
            ]);
        }

        //
        // -- properties
        //

        searchParameters = new ModuleSearchParameters();
        private totalCount = -1;
        moduleList: Array<Module> = [];
        readonly module = new Module();

        proactiveChaseEmails = [
            { text: "90 Days Before", value: 90 },
            { text: "30 Days Before", value: 30 },
            { text: "14 Days Before", value: 14 },
            { text: "7 Days Before", value: 7 }
        ]
        reactiveChaseEmails = [
            { text: "90 Days After", value: -90 },
            { text: "30 Days After", value: -30 },
            { text: "14 Days After", value: -14 },
            { text: "7 Days After", value: -7 }
        ]
        rejectionChaseEmails = [
            { text: "4 Weeks", value: 28 },
            { text: "8 Weeks", value: 56 },
            { text: "12 Weeks", value: 84 }
        ]

        // computed
        get dialogueTitle(): string {
            if (!this.module) return "- - -";
            if (utils.isEmptyId(this.module.id)) return "New Module";
            return "Edit " + this.module.description;
        }

        get countText(): string {
            return this.totalCount === -1 ? "..." : this.totalCount.toString();
        }

        get isDeletedSearchOptions(): Array<LookupItem> {
            return [
                new LookupItem({ id: TriState.UseDefault, description: "All", isArchived: false }),
                new LookupItem({ id: TriState.True, description: "Deleted", isArchived: false }),
                new LookupItem({ id: TriState.False, description: "Not deleted", isArchived: false })
            ];
        }

        cdmCategoryList: Array<ILookupItem> = [];
        cdmCategoryOptions: Array<ILookupItem> = [];

        tradeList: Array<ILookupItem> = [];
        tradeOptions: Array<ILookupItem> = [];

        scopeList: Array<ILookupItem> = [];
        scopeOptions: Array<ILookupItem> = [];

        //
        // -- Watchers
        //

        @Watch("searchParameters.isDeleted")
        onIsDeletedChanged(value: number) {
            console.log("Is deleted changed = ", value);
            this.refreshSearch();
        }

        //
        // -- methods
        //

        private async loadCdmCategories() {
            this.cdmCategoryList = await apiClient.get(`/api/lookup/search/${LookupGroup.CdmCategory}`);
            const choose = new LookupItem({ id: 0, description: "Please choose...", isArchived: false } as ILookupItem);
            this.cdmCategoryOptions = [choose, ...this.cdmCategoryList.filter((lu: ILookupItem) => lu.id > 0 && !lu.isArchived)];
        }

        private async loadTrades() {
            this.tradeList = await apiClient.get("/api/trade/lookups");
            const choose = new LookupItem({ id: 0, description: "Please choose...", isArchived: false } as ILookupItem);
            this.tradeOptions = [choose, ...this.tradeList.filter((lu: ILookupItem) => lu.id > 0 && !lu.isArchived)];
        }

        private async loadScopes() {
            this.scopeList = await apiClient.get("/api/scope/lookups");
            const choose = new LookupItem({ id: 0, description: "Please choose...", isArchived: false } as ILookupItem);
            this.scopeOptions = [choose, ...this.scopeList.filter((lu: ILookupItem) => lu.id > 0 && !lu.isArchived)];
        }

        async edit(moduleData: IModule) {
            this.module.update(moduleData);
            // reset any validation
            this.$v.$reset();
            // show the dialogue
            this.$bvModal.show("moduleDialogue");
            // refresh from server
            const serverModuleData = await apiClient.get(`api/module/Load?id=${moduleData.id}`);
            this.module.update(serverModuleData);
        }

        editNew() {
            utils.resetObject(this.module);
            this.$v.$reset();
            this.$bvModal.show("moduleDialogue");
        }

        cancel() {
            this.$bvModal.hide("moduleDialogue");
            utils.resetObject(this.module);
        }

        async save(event: Event) {

            // 'touch' all the fields to activate the validation messages
            this.$v.$touch();
            if (this.$v.module.$invalid) {
                toastr.info("Please fix the highlighted errors", "Validation errors");
                return;
            }
            const response: { newID: number } = await apiClient.post("/api/module/save", this.module, event);
            if (this.module.isNew) {
                this.module.id = response.newID;
            }
            toastr.success("Saved");
            this.$bvModal.hide("moduleDialogue");
            // redo search - or should we just update in place?
            await this.refreshSearch();
        }

        // 'delete' is a reserved word
        async deleteItem(event: Event) {
            if (this.module.isNew) return;
            const shouldDelete: boolean = await this.$bvModal.msgBoxConfirm("Do you want to delete '" + this.module.description + "'?", {
                title: "Delete",
                okVariant: "danger",
                okTitle: "Yes, delete!",
                cancelTitle: "No, leave it",
                hideHeaderClose: true,
                centered: true,
                headerClass: "border-bottom-0",
                footerClass: "border-top-0",
                size: "sm"
            });
            if (!shouldDelete) return;
            await apiClient.post("/api/module/delete", this.module, event);
            toastr.warning("Deleted");
            this.$bvModal.hide("moduleDialogue");
            await this.refreshSearch();
        }

        refreshSearch(event?: Event) {
            this.moduleList = [];
            this.searchParameters.pageNumber = 1;
            this.totalCount = -1;
            this.search(event);
        }

        async infiniteLoadingHandler(stateChanger: StateChanger) {
            if (this.moduleList.length >= this.totalCount && this.totalCount > -1) {
                stateChanger.complete();
                return;
            }
            this.searchParameters.pageNumber += 1;
            await this.search();
            if (this.moduleList.length >= this.totalCount) {
                stateChanger.complete();
            }
            else {
                stateChanger.loaded();
            }
        }

        private async search(event?: Event) {
            const response = await apiClient.post("/Api/Module/Search", this.searchParameters, event);
            if (this.searchParameters.pageNumber === 1) {
                this.totalCount = response.count;
            }
            this.moduleList.push(...response.list.map((s: IModule) => new Module(s)));
        }

        adminActions(module: IModule): string {
            if (module.rejectionAdminActionRequired && module.acceptedAdminActionRequired) return "Rejected & Accepted";
            if (module.rejectionAdminActionRequired) return "Rejected";
            if (module.acceptedAdminActionRequired) return "Accepted";
            return "";
        }


        onUploaded(documentToAdd: ModelDocument) {
            this.module.documents.push(documentToAdd);
            // no point adding ID as it won't have one until it's saved
        }

        onRemove(documentToRemove: ModelDocument) {
            this.module.documents = this.module.documents.filter(d => d.filename !== documentToRemove.filename);
            this.module.documentIDs = this.module.documentIDs.filter(id => id != documentToRemove.id);
        }

        private iconUrl(document: ModelDocument): string {
            return utils.iconUrl(document);
        }

        async download(doc: ModelDocument) {
            fileDownload.download(doc);
        }

        @Validations()
        validations() {
            const validations = {
                module: {} as any // eslint-disable-line @typescript-eslint/no-explicit-any
            };
            validations.module.description = { required };
            return validations;
        }
    }
