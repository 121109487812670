

    import Vue from "vue";
    import Component from "vue-class-component";
    import { Prop } from "vue-property-decorator";
    import { LookupItem } from '@/model/LookupItem';

    @Component({})
    export default class DualListBox extends Vue {

        @Prop({ required: true })
        private idList!: Array<number>;

        @Prop({ required: true })
        private lookupList!: Array<LookupItem>;

        @Prop({ default: "" })
        private addListTitle!: string;

        @Prop({ default: "" })
        private removeListTitle!: string;

        selectedIDsToAdd: Array<number> = [];
        selectedIDsToRemove: Array<number> = [];

        get addedList(): Array<LookupItem> {
            return this.lookupList.filter(l => this.idList.indexOf(l.id) !== -1);
        }

        get removedList(): Array<LookupItem> {
            return this.lookupList.filter(l => this.idList.indexOf(l.id) === -1);
        }

        addItems() {
            const selectedIDs = this.selectedIDsToAdd.slice(); // copy
            this.selectedIDsToAdd = []; // unselect
            console.log("ADD", selectedIDs);
            for(let i = 0; i < selectedIDs.length; i++) {
                console.log("  ... add - id=", selectedIDs[i]);
                this.idList.push(selectedIDs[i]);
            }
        }

        removeItems() {
            const selectedIDs = this.selectedIDsToRemove.slice(); // copy
            this.selectedIDsToRemove = []; // unselect
            console.log("REMOVE", selectedIDs);
            console.log("  ... remove - id list before:", this.idList);
            for(let i = 0; i < selectedIDs.length; i++) {
                const index = this.idList.indexOf(selectedIDs[i]);
                console.log("  ... remove - index=", index);
                if (index === -1) continue;
                this.idList.splice(index, 1);
            }
            console.log("  ... remove - id list after:", this.idList);
        }
    }

